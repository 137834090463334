import Authentication from '@/components/Authentication';
import clsx from 'clsx';
import { useState, useEffect, useRef, useMemo, forwardRef } from 'react';
import UserDropDown from '../UserDropDown';
import styles from './DesktopHeader.module.scss';
import LogoAuth from './LogoAuth';
import Omnisearch from "@/containers/homePage/Omnisearch";
import { constants } from '@/src/utils/constants';
import Currency from '@/components/Currency';
import { ThemeType } from '@/static/contants';
import Link from 'next/link';
import LypSymb from './LypSymb';
import LogoBirds from './LogoBirds';
import LogoBirdsText from './LogoBirdsText';
import BlogSymb from './BlogSymb';
import useHeader from './useHeader';
import EmailConfPopup from '../EmailConfPopup/EmailConfPopup';
import useEmailConfPopup from '../EmailConfPopup/useEmailConfPopup';
import { useRouter } from 'next/router';
import HelpSymb from './HelpSymb';
import { useSelector } from 'react-redux';
import { useAuthentication } from '@/providers/Authentication';
import { AuthenticationPromptType } from "@/utils/customHooks/useAuth";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { Box } from '@mui/material';

const SlideTransition = forwardRef((props, ref) => {
    return <Slide direction={'up'} timeout={300} {...props} ref={ref} />;
});


const DesktopHeader = ({ isTablet }) => {
    const [
        authState,
        lists,
        currencies,
        handleLogout,
        pathLast,
        pathFirst,
        logoColored,
        setLogoColored,
        determineColor,
        positionAbs,
        positionFixed,
        blog
    ] = useHeader({ isMobile: false });
    const {
        isAuthPromptOpen: authOpen,
        toggleAuthPrompt
    } = useAuthentication();

    const [userDropDownOpen, setUserDropDownOpen] = useState(false);
    const [headerSticky, setHeaderSticky] = useState(false);
    const refBand = useRef(null);
    const isAuthenticated = !!authState;
    const clickHandler = authPromptType => {
        if (isAuthenticated) {
            setUserDropDownOpen(state => !state);
            return;
        }

        toggleAuthPrompt(authPromptType);
    };
    const router = useRouter();
    const { screenWidth } = useSelector(state => state.device);

    const needLypSymb = useMemo(
        () => ((screenWidth > 763) && (screenWidth < 900)),
        [screenWidth]);

    const stickyQuery = useMemo(
        () => {
            if ([''].includes(pathLast)) {
                return '.cn__hp__hero';
            } else if (['a', 't', 'l', 'about-us'].includes(pathFirst)) {
                return '.cn__image-picture.hero';
            } else if (['crypto'].includes(pathFirst)) {
                return 'main > section';
            } else {
                return null;
            }
        },
        [pathFirst, pathLast]
    );

    const hasOmni = useMemo(
        () => ([''].includes(pathLast) || ['a', 't', 'l'].includes(pathFirst)),
        [pathFirst, pathLast]
    );

    const logo = (
        <div
            className={clsx(styles.logo, !logoColored && styles.logo_white)}
        >
            {((isTablet && (stickyQuery || ['sr'].includes(pathLast)))
                || ['list_space'].includes(pathLast))
                ? <LogoBirds />
                : <LogoBirdsText />}
        </div>
    );

    const { showConf, setShowConf } = useEmailConfPopup();

    useEffect(() => {
        if (!isAuthenticated) {
            setUserDropDownOpen(false);
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (stickyQuery) {
            const stickyY = document.querySelector(stickyQuery)?.scrollHeight;
            const stickyHandler = () => {
                setHeaderSticky(window.scrollY > stickyY);
            };
            window.addEventListener('scroll', stickyHandler);
            return () => window.removeEventListener('scroll', stickyHandler);
        }
    }, []);

    useEffect(() => {
        if (['crypto'].includes(pathFirst)) {
            return;
        }

        if (stickyQuery) {
            setLogoColored(headerSticky);
        }
    }, [headerSticky, pathFirst]);

    useEffect(() => {
        let timeoutId;
        if (headerSticky) {
            refBand.current.classList.add(styles.band_sticky_hp_anim);
            timeoutId = setTimeout(() => {
                refBand.current?.classList.add(styles.band_sticky_normal);
                refBand.current?.classList.remove(styles.band_sticky_hp_anim);
            }, 400);
        } else {
            refBand.current.classList.remove(styles.band_sticky_anim);
            refBand.current.classList.remove(styles.band_sticky_normal);
        }
        return () => clearTimeout(timeoutId);
    }, [headerSticky]);

    useEffect(() => {
        if (!isAuthenticated && router?.asPath?.includes("redirect_url")) {
            clickHandler(AuthenticationPromptType.Login);
        }
    }, []);

    return (
        <div test-id='geopageDesktopHeader' className={clsx(
            'DesktopHeader__wrapper',
            styles.wrapper,
            positionAbs && styles.abs,
            positionAbs && 'mui-fixed',
            positionFixed && styles.fixed_pos,
            positionFixed && 'mui-fixed',
            (pathFirst === 'hotel' || pathFirst === 'listings') && styles.sticky_pos)}>
            <div
                ref={refBand}
                className={clsx('DesktopHeader__band', styles.band,
                    positionAbs && styles.abs,
                    positionAbs && 'mui-fixed',
                    headerSticky && styles.band_sticky_hp,
                    headerSticky && 'mui-fixed',
                    (pathLast === 'sr') && styles.sr,
                    ['list_space'].includes(pathLast) && styles.abs_lyp,
                    (pathLast === '') && styles.hp,
                    ['hotel', 'listings'].includes(pathFirst) && styles.ldp)}
            >
                <div className={clsx(styles.main, ['list_space'].includes(pathLast) && styles.main_lyp)}>
                    {(pathLast === '')
                        ? <div>{logo}</div>
                        : <Link href={'/'}>
                            <a>
                                {logo}
                            </a>
                        </Link>}

                    {(hasOmni && headerSticky) && (
                        <Omnisearch
                            className='omnisearch_header_embedded'
                            withRecentSearches={false}
                            withActivityInterests={false}
                            withLocationsNearby={false}
                        />
                    )}

                    <nav className={styles.nav}>
                        <ul className={clsx(styles.nav_inner, logoColored && styles.nav_black)}>


                            {(!['list_space'].includes(pathLast)) &&
                                <li className={styles.nav_item}>
                                    <Link href={('/' + pathFirst === constants.list_your_property_route)
                                        ? constants.list_space_route
                                        : constants.list_your_property_route}
                                    >
                                        <a>
                                            {((pathLast === 'sr') || needLypSymb) &&
                                                <div className={styles.nav_item_symb}>
                                                    <LypSymb />
                                                </div>}
                                            {!needLypSymb &&
                                                <p className={styles.nav_item_title}>List your property</p>}
                                            <div className={styles.nav_item_tooltip}>
                                                <p className={styles.nav_item_tooltip_text}>List your property</p>
                                            </div>
                                        </a>
                                    </Link>
                                </li>}
                            {(blog && !isTablet) &&
                                <li className={styles.nav_item}>
                                    <Link href={constants.blog_route}>
                                        <a>
                                            <div className={styles.nav_item_symb}>
                                                <BlogSymb />
                                            </div>
                                            <p className={styles.nav_item_title}>Blog</p>
                                            <div className={styles.nav_item_tooltip}>
                                                <p className={styles.nav_item_tooltip_text}>Blog</p>
                                            </div>
                                        </a>
                                    </Link>
                                </li>}
                            {(router?.asPath?.includes('/list_space')) &&
                                <li className={styles.nav_item}>
                                    <Link href={constants.contact_us_route}>
                                        <a>
                                            <div className={styles.nav_item_symb}>
                                                <HelpSymb className={styles.nav_item_symb_help} />
                                            </div>
                                            <p className={styles.nav_item_title}>Help</p>
                                            <div className={styles.nav_item_tooltip}>
                                                <p className={styles.nav_item_tooltip_text}>Help</p>
                                            </div>
                                        </a>
                                    </Link>
                                </li>}

                            {!['list_space'].includes(pathFirst) &&
                                <li className={styles.nav_currency}>
                                    <Currency
                                        info={currencies}
                                        theme={logoColored ? ThemeType.DARK : ThemeType.LIGHT}
                                    />
                                </li>}
                            <li className={styles.auth_btn_wrapper}>
                                <button
                                    type='button'
                                    onClick={() => {
                                        clickHandler(AuthenticationPromptType.Login);
                                    }}
                                    className={clsx('js-login-prompt-trigger', styles.auth_btn)}
                                >
                                    <LogoAuth state={isAuthenticated} />
                                </button>
                                <button
                                    type='button'
                                    onClick={() => {
                                        clickHandler(AuthenticationPromptType.Login);
                                    }}
                                    className={clsx('js-signup-prompt-trigger', styles.auth_btn_extra)}>
                                </button>
                                {(isAuthenticated && userDropDownOpen) &&
                                    <>
                                        <div className={clsx(styles.user_drop_down,
                                            userDropDownOpen && styles.user_drop_down_open)}
                                        >
                                            <UserDropDown onLogout={handleLogout} />
                                        </div>
                                        <div className={styles.user_drop_down_wrapper} onClick={() => setUserDropDownOpen(false)}>
                                        </div>
                                    </>}
                                {showConf
                                    && <EmailConfPopup name={authState?.name} setShowConf={setShowConf} />}
                            </li>
                        </ul>
                    </nav>

                </div>
                <Dialog
                    open={authOpen}
                    onClose={() => toggleAuthPrompt()}
                    scroll={'paper'}
                    TransitionComponent={SlideTransition}
                >
                    {/* <Box> */}
                    <DialogContent
                        sx={{
                            '&.MuiDialogContent-root': {
                                padding: 0,
                                borderRadius: '10px',
                                scrollbarWidth: 'none',
                                scrollbarWidth: 'thin',
                                // scrollbarGutter: 'stable',
                                // scrollbarGutter: 'stable both-edges',
                            },
                        }}
                    >
                        <Authentication />
                    </DialogContent>
                    {/* </Box> */}
                </Dialog>
            </div>
        </div>
    );
};

export default DesktopHeader;
