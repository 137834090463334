import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

//Libraries
import SimpleBar from 'simplebar-react';
import smoothscroll from 'smoothscroll-polyfill';
import clsx from 'clsx';

//Import methods
import { handleArrowClick, setArrows, setCard, sliderScrolling } from './utils';
import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';

//Components
import NextArrow from './components/NextArrow';
import PrevArrow from './components/PrevArrow';
import Card from './components/Card';


const Slider = ({data,count,card,cardMargin,cardWidth = false, showBar,cardLink, children, showArrows, recalcInterval }) => {


    //Check device
    const { isDesktop } = useDeviceDetect();

    //Refs
    const sliderRef = useRef(null);
    const sliderCardsRef = useRef(null);
    const prevIndexRef = useRef(0);
    const scrollIndexRef = useRef(0);

    const [index, setIndex] = useState(0);
    // const [isClicked, setIsClicked] = useState(false);

    //States
    const [hideArrows, setHideArrows] = useState(false);

    //Methods

    //------ set width to the cards
    useEffect(() => {
        smoothscroll.polyfill();
        const callFunction = () => setCard(sliderRef.current, cardMargin, count);
        callFunction();
        window.addEventListener('load', callFunction);
        window.addEventListener('resize', callFunction);

        // This is a workaround fix for https://cuddlynest.atlassian.net/browse/QA-818
        // please refactor ASAP
        let recalculationInterval;
        if ((typeof recalcInterval === 'number') && (recalcInterval > 0)) {
            recalculationInterval = setInterval(callFunction, recalcInterval);
        }
        return () => {
            clearInterval(recalculationInterval);
            window.removeEventListener('resize', callFunction);
            window.removeEventListener('load', callFunction);
        };
    }, [recalcInterval]);

    //------ Set arrows
    useEffect(() => {
        setArrows(sliderRef.current,cardMargin,sliderCardsRef.current,setHideArrows);
    },[]);

    //------ Scrollbar dragging event
    useEffect(() => {
        const slider = sliderRef.current;
        let scrollContent = slider.querySelector('.simplebar-content-wrapper');

        const callFunction = () => {
            sliderScrolling(slider);

            const cards = [...slider.querySelectorAll('.cn__card')];
            const ind = cards.findLastIndex(card => {
                return (card.getBoundingClientRect().x - 2 <= slider.getBoundingClientRect().x) ||
                    (card.getBoundingClientRect().x + 2 <= slider.getBoundingClientRect().x);
            });
            scrollIndexRef.current = ind;

            /*
            if(!isClicked) {
                const parent = sliderRef.current; 
                const getCards = [...sliderRef.current.querySelectorAll('.cn__card')];
                let getIndex = 0
                getCards.forEach((v,i)=>{
                    if((parent.getBoundingClientRect().x > (v.getBoundingClientRect().x+30))) {
                        getIndex = i;
                    }
                })

                if(getIndex < getCards.length-count && getIndex >= 0) {
                    setIndex(getIndex === 0 ? 0 : getIndex)
                }
            }
            */
        };
        scrollContent.addEventListener('scroll', callFunction);
        return () => scrollContent.removeEventListener('scroll', callFunction);
    }, [count]);

    //------ pass ref to arrows
    const getParent = () => {
        return sliderRef.current;
    }

    //Render
    const renderCards = (data || [])
        .map((el,i) =>
            typeof card !== 'undefined'
                ? <Card type={card} data={el} link={cardLink} index={i} getparent={getParent} key={i}/>
                : null
        )
        .filter(Boolean);

    const getCardCount = useCallback(() => {
        if (!sliderRef.current) return;
        const getCards = [...sliderRef.current.querySelectorAll('.cn__card')];
        return getCards.length;
    },
    [sliderRef.current]);

    useEffect(() => {
        // console.log('index >> ', index);
        // console.log('prevIndexRef.current >> ', prevIndexRef.current);
        // console.log('scrollIndexRef.current >> ', scrollIndexRef.current);
        if (index !== prevIndexRef.current) {
            handleArrowClick(
                getParent,
                cardMargin,
                cardWidth,
                index
            );
            prevIndexRef.current = index;
        }
    }, [
        handleArrowClick,
        getParent,
        cardMargin,
        cardWidth,
        index
    ]);
    
    return (
        <div className={(clsx('cn__slider',{'cn__slider__noscrollbar': !showBar}))} ref={sliderRef}>
            <SimpleBar autoHide={false}>
                <div test-id='hp-deal_cards' className='cn__slider__cards' ref={sliderCardsRef}>
                        {children || renderCards}
                </div>
            </SimpleBar>
            {(showArrows || (!hideArrows && isDesktop)) && (
                <div className='cn__slider__arrows'>
                    <PrevArrow
                        onClick={() => {
                            setIndex(Math.max(0, prevIndexRef.current - 1));
                        }}
                    />
                    <NextArrow
                        onClick={() => {
                            setIndex(Math.min(getCardCount() - 1, prevIndexRef.current + 1));
                        }}
                    />
                    <div></div>
                    {/* <PrevArrow onClick={() => {
                        if(index>=0 && index, index<(getCardCount-count)+1) {
                            setIsClicked(true)
                            handleArrowClick(getParent,cardMargin,cardWidth,index-1)
                            setIndex(index === 0 ? index : index-1)
                            setTimeout(()=>{
                                setIsClicked(false)
                            },600)
                        }
                    }} />
                    <NextArrow onClick={() => {
                        if(index>=0 && index<(getCardCount-count)) { 
                            setIsClicked(true)
                            handleArrowClick(getParent,cardMargin,cardWidth,index+1)
                            setIndex(index+1)
                            setTimeout(()=>{
                                setIsClicked(false)
                            },600)
                        }
                    }} /> */}
                </div>
            )}
        </div>
    )
};

export default memo(Slider);
