import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import SlideChip from './SlideChip';
import SliderTrack from './SliderTrack';
import { useSettings } from '../contexts/Settings';
import { DestinationModel } from '../Destination';
import { DateRangeModel } from '../DateRange';
import { GuestModel } from '../GuestCount';
import {
    aggregateLocationByType,
    normalizeLocation,
    normalizeLocationString
} from '@/utils/locationNormalizers';
import { DestinationTypeEnum } from '@/static/contants';
import GpsLocation from '@/components/common/icons/GpsLocation';
import CameraPhoto from '@/components/common/icons/CameraPhoto';
import LocationNew from '@/components/common/icons/LocationNew';
import useUserCurrentLocation from '@/utils/customHooks/useUserLocation';
import useUserLocation from '../useUserLocation';
import useProceedToSearch from '../useProceedToSearch';
import { useAttractionsNearby } from '../contexts/AttractionsNearby';
import useDestinationTransition from '../useDestinationTransition';
import ViewportMapBounds from '@/components/map/dto/ViewportMapBounds';
import { getWeekend } from '@/utils/globalFunc';
import { getHistorySearches } from '@/utils/searchHistory';
// import { currentPositionPromise } from '@/src/utils/currentPosition';

const InitialStep = ({
    withAroundCurrentLocation,
    nearbyMaxLimit,
    attractionsMaxLimit
}) => {
    const handleStepTransition = useDestinationTransition(InitialStep);
    const [checkin, checkout] = getWeekend(1);
    const {
        withRecentSearches
    } = useSettings();

    const {attractionsState : attractionsNearbyData} = useAttractionsNearby();
    const nearByLocData = useSelector(state => state.nearByLoc.data);
    // const [currentLocData, setCurrentLocData] = useState({});
    const currentLocData = useUserCurrentLocation();
    const onProceedToSearchResults = useProceedToSearch({
        getValidationValues: (_, datesState, guestsState) => [datesState, guestsState]
    });

    const [resentSearches] = useState(getHistorySearches);
    const { getLocationType, getAddress, getName } = useUserLocation();
    const isNearByLocDataAvailable = nearByLocData.length > 0;
    const isResentSearchesDataAvailable = Boolean(withRecentSearches && (resentSearches.length > 0));
    const isAttractionsNearbyAvailable = Array.isArray(attractionsNearbyData) && (attractionsNearbyData.length > 0);

    // useEffect(() => {
    //     let isComponentMounted = true;

    //     currentPositionPromise
    //         .then(response => {
    //             if (isComponentMounted) {
    //                 setCurrentLocData(response.data?.result);
    //             }
    //         });

    //     return () => {
    //         isComponentMounted = false;
    //     };
    // }, []);

    const { nearbyLimit, attractionsLimit } = useMemo(() => {
        if (typeof nearbyMaxLimit !== 'number' || typeof attractionsMaxLimit !== 'number') {
            return {};
        }

        const totalLimit = (nearbyMaxLimit + attractionsMaxLimit);
        if (!isNearByLocDataAvailable) {
            return {
                attractionsLimit: totalLimit
            };
        }
        if (!isAttractionsNearbyAvailable) {
            return {
                nearbyLimit: totalLimit
            };
        }
        const attractionsLimit = Math.min(attractionsMaxLimit, attractionsNearbyData.length);
        return {
            attractionsLimit,
            nearbyLimit: (totalLimit - attractionsLimit)
        };
    }, [
        nearbyMaxLimit,
        attractionsMaxLimit,
        isNearByLocDataAvailable,
        isAttractionsNearbyAvailable,
        nearByLocData,
        attractionsNearbyData
    ]);

    const onClick = (destination) => {
        handleStepTransition(new DestinationModel({
            ...destination,
            inputValue: aggregateLocationByType({
                type: destination.type,
                action: normalizeLocation,
                isLoading: false
            })(destination.name)
        }));
    };

    const getModels = (
        { id, name, address, type, slug, zoomLevel, mobileZoomLevel, propertyCount, ...rest } = {},
        { startDate, endDate } = {},
        { adultCount, childCount, infantCount, roomCount, childAges } = {}
    ) => {
        type ||= getLocationType(currentLocData);
        address ??= getAddress(currentLocData);
        slug ||= '';
        startDate ||= moment().startOf('day');
        endDate ||= moment().startOf('day').add(1, 'day');

        return [
            new DestinationModel({
                id,
                name,
                address,
                slug,
                type,
                zoomLevel,
                propertyCount,
                mobileZoomLevel,
                ...rest
            }),
            new DateRangeModel({
                startDate,
                endDate
            }),
            new GuestModel({
                adultCount,
                childCount,
                infantCount,
                roomCount,
                childAges
            })
        ];
    };

    const onAroundCurrentLocation = () => {
        onProceedToSearchResults(...getModels(new DestinationModel({
                name: getName(currentLocData),
                address: getAddress(currentLocData),
                type: DestinationTypeEnum.AROUND,
                viewportBounds: new ViewportMapBounds({
                    location: {
                        lat: currentLocData.latitude,
                        lon: currentLocData.longitude
                    }
                })
            }),
            {
                startDate: checkin,
                endDate: checkout
            }
            ))
    };

    // Authored by Bini-man
    const [isIosDevice , setIsIosDevice] = useState(false);

    useEffect(()=>{
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setIsIosDevice(true) ;
        }
    },[]);

    return Boolean(isNearByLocDataAvailable ||
        isResentSearchesDataAvailable ||
        isAttractionsNearbyAvailable ||
        withAroundCurrentLocation) ? (
            <div className={`${isIosDevice && 'omnisearch_content_action-area_initial-step_ios'} omnisearch_content_action-area_initial-step`}>
                {(withAroundCurrentLocation && currentLocData.country) && (
                    <div className='omnisearch_content_action-area_initial-step_around-current-location omnisearch_content_action-area_initial-step_slider-track'>
                        <button
                            className='omnisearch_content_action-area_initial-step_around-current-location_action'
                            onClick={onAroundCurrentLocation}
                        >
                            <GpsLocation />
                            Around my current location
                        </button>
                    </div>
                )}
                {isAttractionsNearbyAvailable && (
                    <SliderTrack
                        // className='os--nearby'
                        title="Nearby attractions"
                    >
                        {attractionsNearbyData.slice(0, attractionsLimit).map(({ name, lat, lon, city, state, country, zoom_in, mob_zoom_in, hotel_count, ...rest }, index) => (
                            <SlideChip
                                key={String(index)}
                                location={name}
                                onClick={() => {
                                    onClick(
                                        ...getModels(new DestinationModel({
                                            ...rest,
                                            id: rest.id ?? normalizeLocationString(city, state, country),
                                            name,
                                            address: normalizeLocationString(city, state, country),
                                            type: DestinationTypeEnum.POI,
                                            zoomLevel: zoom_in,
                                            mobileZoomLevel: mob_zoom_in,
                                            propertyCount: hotel_count,
                                            viewportBounds: new ViewportMapBounds({
                                                location: {
                                                    lat,
                                                    lon
                                                }
                                            })
                                        }))
                                    );
                                }}
                                icon={<CameraPhoto />}
                            />
                        ))}
                    </SliderTrack>
                )}
                {isNearByLocDataAvailable && (
                    <SliderTrack
                        // className='os--nearby'
                        title="Nearby destinations"
                    >
                        {nearByLocData.slice(0, nearbyLimit).map(({
                            id,
                            name,
                            address,
                            lat,
                            long,
                            slug,
                            zoomLevel,
                            mobileZoomLevel,
                            type,
                            viewportBounds,
                            totalInventory
                        }, index) => (
                            <SlideChip
                                key={String(index)}
                                location={name}
                                onClick={() => onClick(
                                    ...getModels({
                                        id,
                                        name,
                                        address,
                                        slug,
                                        zoomLevel,
                                        mobileZoomLevel,
                                        propertyCount: totalInventory,
                                        location: {
                                            lat,
                                            lon: long
                                        },
                                        viewportBounds,
                                        type: type || DestinationTypeEnum.CITY
                                    })
                                )}
                                icon={<LocationNew className='omnisearch_content_action-area_initial-step_slide-chip_content-icon-pin' />}
                            />
                        ))}
                    </SliderTrack>
                )}
                {isResentSearchesDataAvailable && (
                    <SliderTrack
                        title="Recent searches"
                    >
                        {resentSearches.map((resentSearch, index) => (
                            <SlideChip
                                key={resentSearch.addrData?.id ?? String(index)}
                                location={resentSearch.addrData?.name || 'Location'}
                                startDate={moment(resentSearch.startDate)}
                                endDate={moment(resentSearch.endDate)}
                                totalGuests={resentSearch.guests?.adults + resentSearch.guests?.childrenVr + resentSearch.guests?.infants}
                                rooms={resentSearch.guests?.rooms}
                                onClick={() => onProceedToSearchResults(
                                    ...getModels(
                                        {
                                            id: resentSearch.addrData?.id,
                                            name: resentSearch.addrData?.name,
                                            address: resentSearch.addrData?.address,
                                            type: resentSearch.addrData?.type,
                                            slug: resentSearch.addrData?.slug,
                                            mobileZoomLevel: resentSearch.addrData?.mobileZoomLevel,
                                            zoomLevel: resentSearch.addrData?.zoomLevel,
                                            propertyCount: resentSearch.addrData?.propertyCount,
                                        },
                                        {
                                            startDate: moment(resentSearch.startDate),
                                            endDate: moment(resentSearch.endDate)
                                        },
                                        {
                                            adultCount: resentSearch.guests?.adults,
                                            childCount: resentSearch.guests?.childrenVr,
                                            infantCount: resentSearch.guests?.infants,
                                            roomCount: resentSearch.guests?.rooms,
                                            childAges: resentSearch.guests?.children
                                        }
                                    )
                                )}
                            />
                        ))}
                    </SliderTrack>
                )}
            </div>
        ) : null;
};

export default memo(InitialStep);
