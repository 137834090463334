import { useState, useRef, useEffect } from 'react';
import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';
import useMyDeviceDetect from '@/src/utils/customHooks/useMyDeviceDetect';
import styles from './Authentication.module.scss';
import Login from './Login';
import Options from './Options';
import { AuthCloseSVG, AuthBackSVG } from './AuthSVG';
import Signup from './Signup';
import Forgot from './Forgot';
import { useAuthentication } from '@/providers/Authentication';
import { useDispatch } from 'react-redux';
import { resetAuth } from '@/redux/actions/authActions';


const Authentication = () => {
    const {
        changeAuthPromptOpenState: setAuthOpen,
        authPromptType
    } = useAuthentication();
    const [modalType, setModalType] = useState(authPromptType);
    const { isMobile, isTablet, isDesktop } = useMyDeviceDetect();
    const [loginOption, setLoginOption] = useState('email');
    const [signupOption, setSignupOption] = useState(null);
    const wrapperRef = useRef(null);
    const modalRef = useRef(null);
    const dispatch = useDispatch();
    const modalTypeConstants = {
        login: 'Login',
        signup: 'Sign up',
        forgot: 'Forgot password'
    };

    const handleOptionClick = (evt) => {
        if (['email', 'phone'].includes(evt.currentTarget.id)) {
            if (modalType === 'login') {
                setLoginOption(evt.currentTarget.id);
            } else if (modalType === 'signup') {
                setSignupOption(evt.currentTarget.id);
            }
        }
        // if (['facebook', 'google', 'apple'].includes(evt.currentTarget.id)) {
        //     setAuthOpen(false);
        // };
    };
    const changeModalType = () => {
        setModalType('signup')
        setSignupOption("phone")
    }
    const handleClose = () => {
        setModalType('login')
        dispatch(resetAuth());
        if (!isMobile) {
            setAuthOpen(false);
        } else {
            // wrapperRef.current.classList.remove(styles.mobile_visible);
            setTimeout(() => {
                setAuthOpen(false);
            }, 500);
        }
    };

    // useEffect(() => {
    //     const visible = isMobile ? styles.mobile_visible : styles.desktop_visible;
    //     const timeoutID = setTimeout(() => wrapperRef.current.classList.add(visible), 1);
    //     return () => clearTimeout(timeoutID);
    // }, []);

    // const handleParentClick = (evt) => {
    //     if (!evt.target.closest(`.${modalRef.current.className}`)) {
    //         setAuthOpen(false);
    //     }
    // };

    return (
        <div ref={modalRef} className={isMobile
            ? styles.mobile_inner
            : styles.desktop_inner}>

            <header className={isMobile
                ? styles.mobile_header
                : styles.desktop_header}>
                <div className={isMobile
                    ? styles.mobile_header_title
                    : styles.desktop_header_title}>
                    {modalTypeConstants[modalType]}
                </div>
                {isMobile
                    ? (
                        <span
                            className={styles.mobile_header_close}
                            onClick={() => handleClose()}>
                            <AuthBackSVG />
                        </span>
                    )
                    : (
                        <span
                            className={styles.desktop_header_close}
                            onClick={() => handleClose()}>
                            <AuthCloseSVG />
                        </span>
                    )}
            </header>

            <section className={isMobile
                ? styles.mobile_content
                : styles.desktop_content}>

                {modalType === 'login' && (
                    <>
                        <Login
                            loginOption={loginOption}
                            setAuthOpen={setAuthOpen}
                            changeModalType={changeModalType}
                            setLoginOption={setLoginOption}
                        />
                        <div className={styles.login_refto_signup}>
                            Don't have an account?
                            <a
                                className={styles.login_refto_signup_a}
                                onClick={() => setModalType('signup')}
                            >Sign up</a>
                        </div>
                        <div className={styles.login_more_otions}>
                            <div className={styles.login_more_otions__line}>
                            </div>
                            <div className={styles.login_more_otions__text}>
                                <span className={isMobile
                                    ? styles.login_more_otions__text_span_mobile
                                    : styles.login_more_otions__text_span_desktop}>
                                    more log in options
                                </span>
                            </div>
                        </div>
                        <Options
                            modalType={modalType}
                            handleClick={handleOptionClick}
                            excludeOption={loginOption}
                        />
                        <div className={styles.separator}></div>
                        <div className={styles.forgot_link}>
                            <a
                                className={styles.forgot_link_inner}
                                onClick={() => setModalType('forgot')}
                            >
                                Forgot your password?
                            </a>
                        </div>
                    </>
                )}

                {modalType === 'signup' && (
                    <>
                        {!signupOption &&
                            <>
                                <Options
                                    modalType={modalType}
                                    setModalType={setModalType}
                                    handleClick={handleOptionClick}
                                    setAuthOpen={setAuthOpen}
                                />
                                <div className={styles.separator}></div>
                                <div className={styles.disclaimer}>
                                    <div className={styles.disclaimer_inner}>
                                        {'By signing up, you agree to our '}
                                        <a href='/terms-and-conditions'>Terms and Conditions</a>
                                        {' and our '}
                                        <a href='/privacy-policy'>Privacy and Cookie Policy.</a>
                                    </div>
                                </div>
                            </>
                        }
                        {signupOption &&
                            <Signup
                                signupOption={signupOption}
                                setAuthOpen={setAuthOpen} />}
                    </>
                )}

                {modalType === 'forgot' &&
                    <Forgot
                        loginOption={loginOption}
                        setAuthOpen={setAuthOpen} />}

            </section>

        </div>
    );
};

export default Authentication;
