import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { DayPickerRangeController } from '@/components/formComponents/DatePickerWrapper/DateRangePickerWrapper';
import DateRangeModel from './DateRangeModel';
import StepEnum, { MOBILE_THRESHOLD } from '../enum';
import useResize from '../useResize';
import 'react-dates/initialize';
import { constants } from '@/src/utils/constants';
import useUpdateLocale from '@/src/utils/customHooks/useUpdateLocale';
import useInterests from '../Interests/useInterests';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import clsx from 'clsx';
import DatePickerInOutDates from '@/components/formComponents/DatePickerInOutDates/DatePickerInOutDates';
import { useSelector } from 'react-redux';
import CalendarTooltip from 'components/Molecules/Form/CalendarTooltip/CalendarTooltip';
import StartEndCalendarTooltip from 'components/Molecules/Form/CalendarTooltip/StartEndCalendarTooltip';


const DateRangePicker = ({ value, onChange, setStep, searchActionAreaSlot }) => {
    const [focusedInputState, setFocusedInputState] = useState('startDate');
    const { screenWidth } = useSelector(state => state.device);
    const isMobile = useRef(false);

    const [calendarBodyElem, setCalendarBodyElem] = useState(null);

    const { data } = useInterests();
    const nextStep = data.length > 0
        ? StepEnum.INTERESTS
        : StepEnum.GUESTS;

    const nextStepRef = useRef(nextStep);
    nextStepRef.current = nextStep;

    // Investigation due to reopen https://cuddlynest.atlassian.net/browse/FE-1514
    // useUpdateLocale(moment);

    const getCalendarSettings = useCallback(() => {
        if (screenWidth <= MOBILE_THRESHOLD) {
            isMobile.current = true;
            return {
                orientation: 'verticalScrollable',
                numberOfMonths: constants.calendarMonthsMobile,
                verticalHeight: 800,
                daySize: (screenWidth > 400) ? 52 : 44,
            };
        }
        return {
            numberOfMonths: (screenWidth > 1000) ? 2 : 1,
            daySize: 44
        };
    }, [screenWidth]);

    const [calendarSettings, setCalendarSettings] = useState(getCalendarSettings);

    const onFocusChange = useCallback(focusedInput => {
        setFocusedInputState(focusedInput);
    }, []);

    const onDatesChange = useCallback(({ startDate, endDate }) => {
        if (isMobile.current && startDate && endDate) {
            if (focusedInputState === 'startDate') {
                onChange(new DateRangeModel({ startDate }));
                return;
            } else {
                setFocusedInputState('startDate');
            }
        }

        onChange(new DateRangeModel({ startDate, endDate }));

        if (!isMobile.current && startDate && endDate) {
            setStep(nextStepRef.current);
        }
    }, [onChange, setStep, focusedInputState]);


    const isOutsideRange = useCallback(day => (
        day.isBefore(moment(), 'day') ||
        day.isAfter(moment().add(constants.calendarMonths - 1, 'months').endOf('month'))
        // day.isAfter(moment().add(constants.calendarMonths, 'months'), 'month')
    ), []);

    useEffect(() => {
        const { startDate, endDate } = value;
        if (!startDate && !endDate) {
            setFocusedInputState('startDate');
        }
    }, [value]);

    useResize(() => {
        setCalendarSettings(getCalendarSettings);
    });

    return (
        <>
            <div className="omnisearch_content_action-area_date-range-picker">
                <div className='date-picker-dates-omni'>
                    <div className='date-picker-dates-omni-inner'>
                        <DatePickerInOutDates
                            startDate={value.startDate}
                            endDate={value.endDate}
                            focused={focusedInputState}
                            setFocus={setFocusedInputState}
                            disabled={{
                                startDate: false,
                                endDate: !Boolean(value.startDate)
                            }}
                        />
                    </div>
                </div>
                <div ref={node => setCalendarBodyElem(node)} className={'date-picker-body'}>
                    <DayPickerRangeController
                        startDate={value.startDate}
                        endDate={value.endDate}
                        focusedInput={focusedInputState}
                        onFocusChange={onFocusChange}
                        onDatesChange={onDatesChange}
                        renderDayContents={day => {
                            if (day.isSame(value.startDate, 'day')) {
                                return (
                                    <StartEndCalendarTooltip
                                        start={value.startDate}
                                        end={value.endDate}
                                        open={!!value.startDate && !value.endDate}
                                        boundaryElement={isMobile.current ? null : calendarBodyElem}
                                        flipPadding={{ top: isMobile.current ? 110 : 70 }}
                                        observerElement={document.querySelector('.CalendarMonthGrid')}
                                    >
                                        <div className={'CalendarDay__inner'} key={day.format('YYYY-MM-DD')}>
                                            {day.format('D')}
                                        </div>
                                    </StartEndCalendarTooltip>
                                );
                            }

                            if (day.isSame(value.endDate, 'day')) {
                                return (
                                    <StartEndCalendarTooltip
                                        start={value.startDate}
                                        end={value.endDate}
                                        open={!!value.startDate && !!value.endDate}
                                        boundaryElement={isMobile.current ? null : calendarBodyElem}
                                        // boundaryElement={calendarBodyElem}
                                        flipPadding={{ top: isMobile.current ? 110 : 70 }}
                                        observerElement={document.querySelector('.CalendarMonthGrid')}
                                    >
                                        <div className={'CalendarDay__inner'} key={day.format('YYYY-MM-DD')}>
                                            {day.format('D')}
                                        </div>
                                    </StartEndCalendarTooltip>
                                );
                            }

                            const eom = (day.isSame(day.clone().endOf('month'), 'day'));
                            const som = (day.isSame(day.clone().startOf('month'), 'day'));
                            return (
                                <>
                                    {eom && <div className={'CalendarDay__end-of-month'}></div>}
                                    {som && <div className={'CalendarDay__start-of-month'}></div>}
                                    <div className={'CalendarDay__inner'} key={day.format('YYYY-MM-DD')}>{day.format('D')}</div>
                                </>
                            );
                        }}
                        keepOpenOnDateSelect
                        isOutsideRange={isOutsideRange}
                        minDate={moment().startOf("day")}
                        maxDate={moment().add(constants.calendarMonths - 1, 'months').endOf('month')}
                        {...calendarSettings}
                    />
                </div>
                {!isMobile.current &&
                    <div className='day-picker-clear'>
                        <button
                            className='day-picker-clear-button'
                            onClick={() => onChange(new DateRangeModel())}
                        >
                            Clear dates
                        </button>
                    </div>}
            </div>
            {searchActionAreaSlot}
        </>
    )
};

export default memo(DateRangePicker);
